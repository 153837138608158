<template>
  <layout-page :page-description="pageDescription">
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          small
          text
          @click="saveReverseRelation()"
          :loading="dataContext.loading"
          >
          <v-icon>save</v-icon> {{translate('Save')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <!--
    <v-card><v-card-text>{{changes}}</v-card-text></v-card>
    -->
    <v-layout row wrap v-if="dataContext.loading">
      <v-flex md6>
        <v-skeleton-loader type="table" />
      </v-flex>
      <v-flex md6>
        <v-skeleton-loader type="table" />
      </v-flex>
    </v-layout>
    <v-layout row wrap class="equal-height-cards" v-if="!dataContext.loading">
      <v-flex md6>
        <v-card>
          <v-card-text>
            <collection-table
              :loading="dataContext.loading"
              :items="dataContext.reverseRelation.sources"
              :schema="dataContext.reverseRelation.sourceCollectionSchema"
              :sortable="false"
              >
              <template v-slot:delete-item="{item, items}">
                <confirm-button>
                  <template v-slot:button="confirm">
                    <v-btn text small icon color="warning" @click="confirm">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:confirm="cancel">
                    <v-item-group multiple>
                      <v-btn text small color="error" @click="unreferenceItem({item, items: dataContext.reverseRelation.sources})">
                        <v-icon>delete</v-icon> {{translate('Unreference this item?', 'remove.reverse-relation.item')}}
                      </v-btn>
                      <v-btn text small icon @click="cancel">
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </v-item-group>
                  </template>
                </confirm-button>
              </template>
            </collection-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md6>
        <v-card>
          <v-card-text>
            <collection-table
              :loading="dataContext.loading"
              :items="dataContext.sourceCollection.items"
              :schema="dataContext.sourceCollection.schema"
              :channels="dataContext.sourceCollection.channels"
              :relations="dataContext.sourceCollection.relations"
              :reverse-relations="dataContext.sourceCollection.reverseRelations"
              :searchable="true"
              :sortable="true"
              >
              <template v-slot:edit-item="{item}">
                <td style="width: 1px">
                  <v-btn
                    small
                    icon
                    text
                    color="primary"
                    @click="referenceItem({item, items: dataContext.reverseRelation.sources})">
                    <v-icon>add</v-icon>
                  </v-btn>
                </td>
              </template>
            </collection-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </layout-page>
</template>
<script>
import axios from 'axios'
import LayoutPage from '@/components/layout/layout-page.vue'
import {mapGetters} from 'vuex'
import CollectionTable from '@/components/collections/collection-table.vue'
import ConfirmButton from '@/components/confirm-button.vue'

const ChangeSetStateMachine = { // operation -> current state -> new state
  'ref': {
    '': 'ref',
    'ref': 'ref',
    'unref': '',
    'unref-new': 'ref'
  },
  'unref': {
    '': 'unref',
    'unref': 'unref',
    'ref': 'unref-new'
  }
}
export default {
  props: ['relationId', 'targetKey'],
  data: () => ({
    mutations: 1,
    changes: {}
  }),
  components: {
    LayoutPage,
    CollectionTable,
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'relationsConfig', 'collectionsConfig', 'translateRelationName', 'translate', 'createPageDescription']),
    pageDescription () {
      let {sourceCollectionId, targetCollectionId} = this.dataContext.reverseRelation
      return sourceCollectionId && targetCollectionId && this.createPageDescription()
        .addCollection({collectionId: targetCollectionId})
        .addCollection({collectionId: sourceCollectionId})
        .addCollectionItem({collectionId: targetCollectionId, itemKey: this.targetKey})
    }
  },
  asyncComputed: {
    dataContext: {
      async get () {
        let reverseRelation = this.mutations && await this.api.relations.getReverseRelation({relationId: this.relationId, targetKey: this.targetKey})
        let sourceAttributes = this.collectionsConfig.getTableAttributesForLoading(reverseRelation.sourceCollectionId)
        let sourceCollection = await this.api.collections.getCollection({collectionId: reverseRelation.sourceCollectionId, query: {
          attributes: sourceAttributes && sourceAttributes.join(',')
        }})
        return {
          loading: false,
          reverseRelation, sourceCollection
        }
      },
      default: {
        loading: true,
        reverseRelation: {
          sources: [],
          sourceCollectionSchema: {}
        },
        sourceCollection: {
          items: [],
          schema: {}
        }
      }
    }
  },
  methods: {
    async loadData () {
      let {data: relation} = await axios.get(`/api/v1/relation/${this.relationId}/${this.sourceKey}`)
      let {data: targetCollection} = await axios.get(`/api/v1/collection/${relation.targetCollectionId}`)
      return {
        data: {
          relation,
          targetCollection
        }
      }
    },
    referenceItem ({item, items}) {
      if (!items.find(({key}) => key === item.key)) {
        items.push(item)
        this.changes[item.key] = ChangeSetStateMachine.ref[this.changes[item.key] || '']
      }
    },
    unreferenceItem ({item, items}) {
      // const index = items.findIndex(existing => existing === item)
      const index = items.indexOf(item)
      if (index >= 0) {
        items.splice(index, 1)
        this.changes[item.key] = ChangeSetStateMachine.unref[this.changes[item.key] || '']
      }
    },
    async saveReverseRelation () {
      let appends = Object.entries(this.changes).filter(([, op]) => op === 'ref')
        .map(([key]) => ({
          relationId: this.relationId,
          sourceKey: key,
          targets: [{key: this.targetKey}]
        }))
      let removes = Object.entries(this.changes).filter(([, op]) => op === 'unref')
        .map(([key]) => ({
          relationId: this.relationId,
          sourceKey: key,
          targets: [{key: this.targetKey}]
        }))

      const p1 = appends.length ? this.api.relations.appendRelation({update: appends}) : Promise.resolve()
      const p2 = removes.length ? this.api.relations.unlinkRelation({update: removes}) : Promise.resolve()

      await Promise.all([p1, p2])
      this.changes = {}
      this.mutations = this.mutations + 1
    },
  }
}
</script>
